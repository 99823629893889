import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Login } from "../components/pages/Login";
import { HomeRoutes } from "./HomeRoutes";
import { Page404 } from "../components/pages/Page404";
import { HeaderLayout } from "../components/templates/HeaderLayout";
import { LoginUserProvider } from "../providers/LoginUserProvider";

export const Router = () => {
  return (
    <BrowserRouter>
      <LoginUserProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home">
            {HomeRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element=<HeaderLayout>{route.element}</HeaderLayout>
              />
            ))}
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </LoginUserProvider>
    </BrowserRouter>
  );
};
